body,
html,
#root,
.wrapper {
  height: 100%;
  width: 100%;
  margin: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.bar-wrapper {
  margin-top: 5px;
}

.button-wrapper {
  text-align:right;
}

.button {
  margin-right:10px !important;
}

.editor-wrapper-class{
  height: calc(100vh - 275px);
}
.editor-class{
  height: calc(100vh - 375px);
  border: solid 1px #909090;
  padding-left: 10px;
  padding-right: 10px;
}

.editor-class{
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
}

.biteditor-wrapper-class{
  min-height:350px;
}

.biteditor-class{
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  border: solid 1px #909090;
  padding-left: 10px;
  padding-right: 10px;
}
