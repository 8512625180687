body,
html,
#root,
.wrapper {
  height: 100%;
  width: 100%;
  margin: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.bar-wrapper {
  margin-top: 5px;
}

.bitsorter-tree-wrapper .rst__nodeContent{
  min-width:90%;
}